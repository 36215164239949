/* =========================================================
   Animation
   ============================================================ */
@keyframes arrowLeft {
    0% {
        transform: translateX(0);
    }

    10% {
        transform: translateX(-5px);
    }

    20% {
        transform: translateX(0);
    }

    30% {
        transform: translateX(-5px);
    }

    40% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes arrowRight {
    0% {
        transform: translateX(0);
    }

    10% {
        transform: translateX(5px);
    }

    20% {
        transform: translateX(0);
    }

    30% {
        transform: translateX(5px);
    }

    40% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes arrowTop {
    0% {
        transform: translateY(0);
    }

    10% {
        transform: translateY(-5px);
    }

    20% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(-5px);
    }

    40% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes arrowBottom {
    0% {
        transform: translateY(0);
    }

    10% {
        transform: translateY(5px);
    }

    20% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(5px);
    }

    40% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
