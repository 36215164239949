// Mixins
#m {
    .stretch() {
        position: relative;
        //max-width: @wrapper-width;
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
    }

    .stretchBG(@bg) {
        position: relative;

        &:before {
            #m .stretch();

            content: "";
            display: block;
            position: absolute;
            background: @bg;
            top: 0;
            height: 100%;
        }
    }

    .card-appearance {
        position: relative;
        padding: 15px;
        // border: 1px solid @color-border;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05), 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    .group() {
        zoom: 1;

        &:after,
        &:before {
            content: '';
            display: table;
        }
        &:after {
            clear: both;
        }
    }

    .center() {
        margin: 0 auto;
    }

    .rmvtxt_destroy(){
        text-indent: 0;
        overflow: visible;
        display: block;
    }

    .box-sizing(@model: content-box) {
        -webkit-box-sizing: @arguments;
        -moz-box-sizing: @arguments;
        -ms-box-sizing: @arguments;
        -o-box-sizing: @arguments;
        box-sizing: @arguments;
    }

    .appearance(@status: none)  {
        -webkit-appearance: @status;
        -moz-appearance: @status;
        -ms-appearance: @status;
        appearance: @status;
    }

    .bg-image(@url, @w: inherit, @h: inherit) {
        &:extend(.rmvtxt);

        width: @w;
        height: @h;
        background: url(@url) no-repeat;
    }

    .bg(@url, @size) {
        background: url(@url) no-repeat top center;
        -webkit-background-size: @size;
        background-size: @size;
    }

    .border-radius(@r) {
        border-radius: @r;
        behavior: url(../border-radius.htc);
    }

    .opacity(@allBrowsers) {
        @IE: @allBrowsers * 100;
        opacity: @allBrowsers;
        filter: alpha(opacity= @IE);
    }

    .box-shadow(@x, @y, @blur, @color, @alpha) {
        -webkit-box-shadow: @x @y @blur rgba(red(@color), green(@color), blue(@color), @alpha);;
        box-shadow: @x @y @blur rgba(red(@color), green(@color), blue(@color), @alpha);
    }

    .box-shadow-inset(@x, @y, @blur, @color, @alpha) {
        -webkit-box-shadow: inset @x @y @blur rgba(red(@color), green(@color), blue(@color), @alpha);;
        box-shadow: inset @x @y @blur rgba(red(@color), green(@color), blue(@color), @alpha);
    }
}


// Extends
.rmvtxt {
    text-indent: -9999px;
    overflow: hidden;
    display: block;
}
