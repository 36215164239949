
        [data-component="hamburger"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

/*!
* Hamburgers
* @description Tasty CSS-animated hamburgers
* @author Jonathan Suh @jonsuh
* @site https://jonsuh.com/hamburgers
* @link https://github.com/jonsuh/hamburgers
*/

@hamburger-padding-x                       : 24px;
@hamburger-padding-y                       : 26px;
@hamburger-layer-width                     : 18px;
@hamburger-layer-height                    : 3px;
@hamburger-layer-spacing                   : 3px;
@hamburger-layer-color                     : #083085;
@hamburger-layer-border-radius             : 1.5px;
@hamburger-hover-opacity                   : 0.7;
@hamburger-hover-transition-duration       : 0.15s;
@hamburger-hover-transition-timing-function: linear;

// Hamburger
// ==================================================
.hamburger {
    padding: @hamburger-padding-y @hamburger-padding-x;
    display: block;
    cursor: pointer;

    transition-property: opacity, filter;
    transition-duration: @hamburger-hover-transition-duration;
    transition-timing-function: @hamburger-hover-transition-timing-function;

    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}

.hamburger-box {
    width: @hamburger-layer-width;
    height: @hamburger-layer-height * 3 + @hamburger-layer-spacing * 2;
    display: block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: @hamburger-layer-height / -2;

    &,
    &::before,
    &::after {
        width: @hamburger-layer-width;
        height: @hamburger-layer-height;
        background-color: @hamburger-layer-color;
        border-radius: @hamburger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }

    &::before {
        top: (@hamburger-layer-spacing + @hamburger-layer-height) * -1;
    }

    &::after {
        bottom: (@hamburger-layer-spacing + @hamburger-layer-height) * -1;
    }
}

/*
* Collapse
*/
.hamburger--collapse {
    .hamburger-inner {
        top: auto;
        bottom: 0;
        transition-duration: 0.13s;
        transition-delay: 0.13s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::after {
            top: (@hamburger-layer-spacing * 2 + @hamburger-layer-height * 2) * -1;
            transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
            opacity 0.1s linear;
        }

        &::before {
            transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
            transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }

    &.is-active {
        .hamburger-inner {
            transform: translate3d(0, (@hamburger-layer-spacing + @hamburger-layer-height) * -1, 0) rotate(-45deg);
            transition-delay: 0.22s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            &::after {
                top: 0;
                opacity: 0;
                transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                opacity 0.1s 0.22s linear;
            }

            &::before {
                top: 0;
                transform: rotate(-90deg);
                transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
}

/*
* Arrow
*/
.hamburger--arrow.is-active {
    .hamburger-inner {
        &::before {
            transform: translate3d(@hamburger-layer-width * -0.2, 0, 0) rotate(-45deg) scale(0.7, 1);
        }

        &::after {
            transform: translate3d(@hamburger-layer-width * -0.2, 0, 0) rotate(45deg) scale(0.7, 1);
        }
    }
}

        }