
        [data-component="gallery-mosaic"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

#m .group();
margin: 0 -5px 35px -5px;

.card-photo {
    position: relative;
    float: left;
    cursor: pointer;
    margin: 5px;
    overflow: hidden;

    .desktop &:hover {
        &:before {
            opacity: .4;
        }

        img {
            transform: scale(1.1) translateZ(0);
        }

        .counter-photo {
            padding-left: 40px;

            i {
                transform: translateY(-50%) rotate(90deg) scale(1.2);
            }
        }
    }

    &:before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: all .4s ease;
        background-color: @color-8;
        z-index: 1;
    }

    img {
        display: block;
        width: 100%;
        transform: scale(1) translateZ(0);
        transition: transform .4s ease;
    }

    .counter-photo {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 50px;
        line-height: 100%;
        color: #fff;
        font-family: @font-regular;
        padding-left: 32px;
        transition: padding-left .4s ease;
        transform: translate(-50%,-50%);
        z-index: 1;

        i {
            position: absolute;
            display: block;
            width: 28px;
            height: 28px;
            top: 55%;
            left: 0;
            transition: transform .4s ease;
            transform: translateY(-50%);

            svg {
                display: block;
                width: 100%;
                height: 100%;
            }

            use {
                fill: #fff;
            }
        }
    }

    &:nth-child(1), &:nth-child(2) {
        width: calc(~"50% - 10px");
    }

    &:nth-child(3) {
        width: calc(~"67% - 10px");
    }

    &:nth-child(4), &:nth-child(5) {
        width: calc(~"33% - 10px");
    }

    &.has-more {
        &:before {
            opacity: .4;
        }
    }
}

@media(max-width: 767px) {
    margin: 0 -2px 35px -2px;

    .card-photo {
        margin: 2px;

        .counter-photo {
            font-size: 20px;
            padding-left: 16px;

            i {
                width: 14px;
                height: 14px;
            }
        }

        &:nth-child(1), &:nth-child(2) {
            width: calc(~"50% - 4px");
        }

        &:nth-child(3) {
            width: calc(~"67% - 4px");
        }

        &:nth-child(4), &:nth-child(5) {
            width: calc(~"33% - 4px");
        }
    }

}
        }