
        [data-component="gallery-modal"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

position: fixed;
display: none;
opacity: 0;
width: 100%;
top: 0;
left: 0;
bottom: 0;
z-index: 9998;
background-color: rgba(0,0,0,.9);

.btn-close-modal {
    width: 16px;
    height: 16px;
    top: 10px;
    right: 10px;
    box-sizing: content-box;
    padding: 10px;
    z-index: 2;

    i use {
        fill: #fff;
    }
}

.slider-arrow {
    box-sizing: content-box;
    margin-top: -74px;
    padding: 10px;
}

.slider-arrow-prev {
    left: 10px;
}

.slider-arrow-next {
    right: 10px;
}

.slider-gallery-zoom {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 120px;
    left: 0;
    padding: 20px 56px 0;
    box-sizing: border-box;

    .slider-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }

        iframe {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

.slider-gallery-thumb {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    overflow: hidden;

    .slider-item {
        position: relative;
        width: auto;
        height: 100px;
        cursor: pointer;
        overflow: hidden;
        box-sizing: border-box;
        border: 4px solid transparent;
        transition: border .4s ease;

        .desktop &:hover {
            &:not(.swiper-slide-active) {
                &:before {
                    opacity: .4;
                }

                img {
                    transform: scale(1.1) translateZ(0);
                }
            }
        }

        &.swiper-slide-active {
            cursor: default;
            border: 4px solid @color-primary;

            &:before {
                opacity: .6;
                background-color: #000;
            }
        }

        &:before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            transition: all .4s ease;
            background-color: @color-8;
            z-index: 1;
        }

        img {
            display: block;
            height: 100%;
            transform: scale(1) translateZ(0);
            transition: transform .4s ease;
        }
    }
}

@media(max-width: 1023px) {
    top: 68px;
}
        }