
        [data-component="alert"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

.alert {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-family: @font-bold;
    border-radius: 32px;
    border-width: 2px;
    border-style: solid;
    padding: 14px 25px;
    margin-bottom: 40px;

    span {
        position: relative;
        display: inline-block;
        padding-left: 30px;

        i {
            position: absolute;
            display: block;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 0;
            margin-right: 10px;
            transform: translateY(-50%);

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.alert-success {
    border-color: @color-success;
    color: @color-success;
    background-color: lighten(@color-success, 50%);

    span i use {
        fill: @color-success;
    }
}

.alert-error {
    border-color: @color-error;
    color: @color-error;
    background-color: lighten(@color-error, 30%);

    span i use {
        fill: @color-error;
    }
}

.alert-warning {
    border-color: @color-warning;
    color: @color-warning;
    background-color: lighten(@color-warning, 30%);

    span i use {
        fill: @color-warning;
    }
}

.alert-info {
    border-color: @color-info;
    color: @color-info;
    background-color: lighten(@color-info, 40%);

    span i use {
        fill: @color-info;
    }
}

@media screen and (max-width: 767px) {
    .alert {
        padding: 10px 25px;

        span {
            padding: 25px 0 0;

            i {
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}

        }