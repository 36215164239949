
        [data-component="side-menu"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

ul li {
    color: @color-text;
    font-size: 13px;
    font-family: @font-light;
    border-bottom: 1px solid @color-border;

    &:first-child {
        border-top: 1px solid @color-border;
    }

    a {
        position: relative;
        display: block;
        color: @color-text;
        padding: 14px 12px;
        transition: color .4s ease;

        .desktop &:hover {
            &:not(.active) {
                color: @color-title;

                i {
                    transform: translateX(5px);

                    use {
                        fill: @color-title;
                    }
                }
            }

        }

        &.active {
            color: @color-active !important;
            font-family: @font-bold;
        }

        i {
            position: absolute;
            display: block;
            width: 7px;
            height: 12px;
            top: 50%;
            right: 12px;
            margin-top: -6px;
            transition: transform .4s ease;

            svg {
                display: block;
                width: 100%;
                height: 100%;
            }

            use {
                fill: @color-text;
                transition: all .4s ease;
            }
        }
    }

    &.box-regions {
        padding: 14px 12px;

        span {
            display: block;
            margin-bottom: 7px;
        }

        .field-form {
            width: 100%;
        }
    }
}

        }