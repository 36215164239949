body, html,
div, p,
header, main, section, article,
nav, ul, ol, li,
h1, h2, h3, h4, h5, h6,
blockquote, form, figure {
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, strong, b {
    font-weight: normal;
    font-size: inherit;
}

a,
a:focus {
    text-decoration: none;
    outline: none;
}

body a:hover {
    text-decoration: none;
}

ul, ol, li {
    list-style-type: none;
}

input,
textarea,
select,
button {
    margin: 0;
    padding: 0;
    border: 0 none;
    outline: 0;
    resize: none;
    background-color: initial;
}

* {
    outline: none;
}
