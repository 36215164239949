
        [data-component="card-login"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

#m .card-appearance();
font-size: 12px;
font-family: @font-light;
color: @color-text;

h2 {
    font-size: 15px;
    line-height: 100%;
    color: @color-title;
    font-family: @font-bold;
    margin-bottom: 10px;
    text-align: center;
}

.user {
    text-align: left;
    margin-bottom: 20px;
}

p {
    line-height: 17px;
    margin-bottom: 30px;
}

.button {
    position: relative;
    //max-width: 178px;
    margin: 12px auto 0;

    &.active {
        color: #fff;
        background-color: @color-primary;
    }
}

        }