
        [data-component="gallery-slider"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

margin-bottom: 35px;

.gallery-featured {
    position: relative;
    width: 100%;

    figure {
        width: 100%;
        margin-bottom: 30px;

        img {
            width: 100%;
        }
    }

    p {
        margin-bottom: 20px;
    }
}

.container-slider-gallery-news {
    position: relative;
    width: 100%;
    padding: 0 44px;
}

.slider-gallery-news {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;

    .slider-item {
        position: relative;
        cursor: pointer;
        overflow: hidden;

        .desktop &:hover {
            &:before {
                opacity: .4;
            }

            img {
                transform: scale(1.1) translateZ(0);
            }
        }

        &:before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            transition: all .4s ease;
            background-color: @color-8;
            z-index: 1;
        }

        img {
            display: block;
            width: 100%;
            transform: scale(1) translateZ(0);
            transition: transform .4s ease;
        }
    }
}

@media(max-width: 767px) {
    .container-slider-gallery-news {
        padding: 0 24px;
    }
}
        }