/**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

 body main .kit-login {
    .box-login {
        position: relative;
        float: none;
        margin: 0 auto;
    }
 }

.kit {
    display: block;
    color: @color-text;

    .breadcumb-kit {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid @color-border;
        padding-bottom: 30px;
        margin-bottom: 16px;

        .button {
            width: 100px;
        }

        h2 {
            width: calc(~"100% - 100px");
            font-size: 15px;
            font-family: @font-bold;
            box-sizing: border-box;
            color: @color-title;
            padding-right: 20px;
        }
    }

    table {
        width: 100%;

        tbody > tr:hover {
            td {
                background-color: @color-hover-tr;
            }
        }

        td, th {
            width: 20%;
            color: @color-text;
            text-align: left;
            padding: 14px 12px;
            transition: all .4s ease;

            &:last-child {
                text-align: right;
            }

            &:first-child {
                text-align: left;
                width: 60%;
            }
        }

        th {
            color: @color-primary;
            font-size: 15px;
            font-family: @font-bold;
            border-bottom: 2px solid @color-border;
            padding: 14px 12px;
        }

        td {
            font-size: 13px;
            font-family: @font-light;
            border-top: 1px solid @color-border;

            &:first-child {
                padding: 0 12px;
            }

            a {
                position: relative;
                display: block;
                text-decoration: none;
                color: @color-text;
                font-family: @font-bold;
                padding: 14px 0 14px 36px;
            }
        }

        .far, .fas, .fab {
            position: absolute;
            top: 50%;
            left: 0;
            font-size: 22px;
            margin-right: 10px;
            color: @color-primary;
            transform: translateY(-50%);
        }

        .time {
            margin-left: 10px;
        }
    }
}


@media screen and (max-width: 767px) {
    .kit {
        .box-scroll-table {
            position: relative;
            width: 100%;
            overflow-x: scroll;

            td, th {
                width: 25%;

                &:first-child {
                    width: 50%;
                }
            }
        }

        table {
            min-width: 500px;
        }
    }
}
