
        [data-component="banner-header"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

#m .stretchBG(#ff6b22);

height: 198px;
color: #fff;
padding-top: 18px;
margin-bottom: 64px;
box-sizing: border-box;
background-repeat: no-repeat;
background-position: center;
background-size: cover;

&.bg-01 {
    #m .stretchBG(url(../images/banner/reuniao.jpg) #ff6b22 no-repeat center);
}

&.bg-02 {
    #m .stretchBG(url(../images/banner/maquina.jpg) #ff6b22 no-repeat center);
}

&.bg-03 {
    #m .stretchBG(url(../images/banner/moca-refeitorio.jpg) #ff6b22 no-repeat center);
}

&.bg-04 {
    #m .stretchBG(url(../images/banner/tv.jpg) #ff6b22 no-repeat center);
}

&.bg-05 {
    #m .stretchBG(url(../images/banner/segurando.jpg) #ff6b22 no-repeat center);
}

&.bg-06 {
    #m .stretchBG(url(../images/banner/salamitos.jpg) #ff6b22 no-repeat center);
}

&.bg-07 {
    #m .stretchBG(url(../images/banner/laje.jpg) #ff6b22 no-repeat center);
}

&.bg-08 {
    #m .stretchBG(url(../images/banner/nuggets.jpg) #ff6b22 no-repeat center);
}

&.bg-09 {
    #m .stretchBG(url(../images/banner/presunto.jpg) #ff6b22 no-repeat center);
}

nav {
    position: relative;
}

h1 {
    position: absolute;
    left: 15px;
    bottom: 25px;
    font-size: 40px;
    font-family: @font-light;
    text-transform: uppercase;
}

@media(max-width: 1023px) {
    margin-bottom: 45px;
}

@media(max-width: 767px) {
    height: 180px;
    margin-bottom: 30px;

    h1 {
        font-size: 30px;
        line-height: 110%;
        left: 0;
    }

    &.bg-01 {
        #m .stretchBG(url(../images/banner/reuniao-mobile.jpg) no-repeat center / cover);
    }

    &.bg-02 {
        #m .stretchBG(url(../images/banner/maquina-mobile.jpg) no-repeat center / cover);
    }

    &.bg-03 {
        #m .stretchBG(url(../images/banner/moca-refeitorio-mobile.jpg) no-repeat center / cover);
    }

    &.bg-04 {
        #m .stretchBG(url(../images/banner/tv-mobile.jpg) no-repeat center / cover);
    }

    &.bg-05 {
        #m .stretchBG(url(../images/banner/segurando-mobile.jpg) no-repeat center / cover);
    }

    &.bg-06 {
        #m .stretchBG(url(../images/banner/salamitos-mobile.jpg) no-repeat center / cover);
    }

    &.bg-07 {
        #m .stretchBG(url(../images/banner/laje-mobile.jpg) no-repeat center / cover);
    }

    &.bg-08 {
        #m .stretchBG(url(../images/banner/nuggets-mobile.jpg) no-repeat center / cover);
    }

    &.bg-09 {
        #m .stretchBG(url(../images/banner/presunto-mobile.jpg) no-repeat center / cover);
    }
}

        }