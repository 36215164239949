
        [data-component="search-form"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

#m .group();

fieldset {
    position: relative;
    border: 0;
    padding: 0;
    margin: 0;
}

.field-search {
    width: 380px;
    padding-right: 30px;

    & + .btn-search {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        border: 0;
        padding: 0 10px;
        background-color: transparent;

        i {
            display: block;
            width: 18px;
            height: 18px;

            svg {
                display: block;
                width: 100%;
                height: 100%;
            }

            use {
                fill: @color-text;
            }
        }
    }
}

.box-autocomplete {
    #m .card-appearance();

    position: absolute;
    display: none;
    width: 510px;
    top: 100%;
    left: 0;
    // border: 1px solid @color-border;
    box-sizing: border-box;
    padding: 20px;
    background-color: #fff;
    z-index: 5;

    .card-horizontal {
        [data-component="card"] {
            figure + .text {
                width: calc(~'100% - 100px');
            }

            .text {
                h2 {
                    margin: 4px 0;
                }

                h2, p {
                    b {
                        font-family: @font-bold;
                    }
                }
            }

            figure {
                width: 100px;
            }
        }
    }

    div:first-child {
        [data-component="card"] {
            border-top: 0;
            margin-top: 0;
        }
    }
}

.pull-right {
    position: relative;
}

.field-filter {
    width: 152px;
}

.btn-open-calendar {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 20px;
    background-color: #fff;
    z-index: 1;

    i {
        position: absolute;
        display: block;
        width: 16px;
        height: 16px;
        left: 50%;
        top: 50%;
        cursor: pointer;
        transform: translate(-50%,-50%);

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

        use {
            fill: @color-text;
        }
    }
}

.box-field-period {
    position: relative;
    margin-left: 20px;

    i {
        position: absolute;
        width: 16px;
        height: 16px;
        right: 10px;
        top: 50%;
        cursor: pointer;
        transform: translateY(-50%);

        svg {
            width: 100%;
            height: 100%;
        }

        use {
            fill: @color-text;
        }
    }
}

.field-period {
    width: 172px;
    padding-right: 36px;
}

@media(max-width: 767px) {
    .pull-left {
        width: calc(~"100% - 64px");
    }

    .field-search {
        width: 100%;
    }

    .field-period {
        width: 44px;
        margin: 0;
        box-sizing: border-box;
        padding: 0 20px;
    }

    .btn-open-calendar {
        display: block;
    }

    .box-field-period {
        i {
            right: 50%;
            padding: 10px;
            box-sizing: content-box;
            transform: translate(50%,-50%);
            background-color: #fff;
        }
    }

    .box-autocomplete {
        width: calc(~"100% + 64px");
        padding: 0 15px 15px;
    }
}

        }