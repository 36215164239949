
        [data-component="header"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

position: relative;
background-color: #fff;
z-index: 2;

// loop definition
.gradient-colors(@list, @i: 1) when (@i <= length(@list)) {
    // extract the right color from the list
    @color: extract(@list, @i);

    // apply the background to the selector
    &:nth-child(@{i}) {
        > a {
            color: @color;
        }

         @media(min-width: 769px) {
            &:hover, &:active, &.hover {
                > a {
                    color: @color;

                    span {
                        border-color: @color;
                    }
                }
            }
        }
    }


    // recursive call for the next color
    .gradient-colors(@list, @i + 1);
}

.nav-secondary{
    #m .group();

    position: relative;
    height: 68px;
    z-index: 2;

    .brand {
        .logo {
            height: 40px;
            margin: 15px 0;

            img {
                height: 100%;
            }
        }
    }

    .menu-secondary {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%) translateZ(0);
        float: none;

        .contact-options {
            position: relative;
            vertical-align: middle;
            display: inline-block;
            top: 4px;

            &:hover {
                .link-down {
                     span {
                        color: @color-active;
                    }

                    i {
                        transform: rotate(-180deg);
                        top: 5px;

                        use {
                            fill: @color-active;
                        }
                    }
                }

                ul {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }
            }

            .link-down {
                font-size: 12px;
                font-family: @font-light;
                color: #4f4f4f;
                //font-weight: 600;
                letter-spacing: .5px;
                padding-bottom: 11px;
                transition: color .3s ease;

                span {
                    display: inline-block;
                }

                i {
                    width: 12px;
                    margin-left: 2px;
                    position: relative;
                    top: 2px;
                    height: 11px;
                    transform-origin: center center;
                    transition: all .3s ease;

                    use {
                        fill: #848484;
                        transition: all .3s ease;
                    }
                }
            }

            ul {
                top: 100%;
                left: -15px;
                min-width: 200px;
                opacity: 0;
                visibility: hidden;
                position: absolute;
                padding: 26px;
                background-color: @color-active;
                box-shadow: 0px 5px 5px 0 rgba(0,0,0,.05), 0 15px 20px 0 rgba(0,0,0,.1);
                transform: translateY(20px);
                transition: all .3s ease;

                li {
                    margin-top: 14px;

                    &:first-child {
                        margin-top: 0;
                    }

                    a {
                        font-family: @font-light;
                        color: #fff;
                        font-size: 13px;
                        transition: color .3s ease;

                        .desktop &:hover {
                            color: lighten(@color-active, 25%);
                        }
                    }
                }
            }
        }

        .lang-options {
            display: inline-block;
            vertical-align: middle;
            margin: 0 38px 0 45px;
            font-size: 12px;

            a, span {
                display: inline-block;
                color: @color-text;
                text-transform: uppercase;
                font-family: @font-bold;
            }

            a {
                transition: color .4s ease;

                .desktop &:hover {
                    &:not(.active) {
                        color: #000;
                    }
                }
            }

            .pipe {
                position: relative;
                top: -1px;
                margin: 0 7px;
            }

            .active {
                color: @color-generic-active;
            }
        }

        .social-links {
            display: inline-block;
            vertical-align: middle;
            line-height: 0;
            margin-right: 5px;

            [data-component="social-links"] {
                .btn {
                    margin-left: 12px;
                }
            }
        }
    }

    .btn-leave {
        display: none;
    }
}

.nav-primary {
    z-index: 1;
    position: relative;

    &:before, &:after {
        #m .stretch();

        content: '';
        height: 6px;
        position: absolute;
        bottom: 0;
        background: linear-gradient(to right, #a63390 4%, #012e89 100%);
        z-index: 0;
    }

    &:after {
        transition: opacity .4s .2s;
        opacity: 0;
        background: @color-border;
        z-index: 1;
    }

    @media screen and (min-width: 1024px) {
        &:hover {
            &:after {
                opacity: 1;
            }

            ul li > a:not(.hover) {
                color: @color-text;
            }
        }
    }

    .menu-primary {
        // display: flex;
        // justify-content: space-between;
        position: relative;
        width: 100%;
        z-index: 2;

        .back-menu {
            display: none;
        }

        > li {
            .gradient-colors(@gradient);

            display: inline-block; // Comentar se for manter o flex do .menu-primary

            @media screen and (min-width: 1024px) {
                &:hover {
                    .sub {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }

            > a {
                // Para evitar conflito com o JavaScript,
                // não adicionar padding diretamente no <a>, se necessário, adidicionar no span.

                display: inline-block;
                color: #8c8c8c;
                font-family: @font-bold2;
                font-size: 15px;
                //font-weight: 600;
                line-height: 1em;

                span {
                    display: inline-block;
                    padding: 23px 7px 18px;
                    color: inherit;
                    border-bottom: 6px solid transparent;
                    transition: color .4s .2s, border-color .4s .2s;
                }
            }

            .sub {
                background-color: #fff;
                z-index: 3;
                left: -60px;
                right: -60px;
                padding: 10px 60px 35px;
                visibility: hidden;
                position: absolute;
                overflow: hidden;
                opacity: 0;
                box-shadow: 0px 5px 5px 0 rgba(0,0,0,.05), 0 15px 20px 0 rgba(0,0,0,.1);
                transition: all .4s .2s;

                ul li {
                    display: block;
                    margin: 0;
                    margin-top: 11px;

                    a {
                        padding: 0;
                        color: @color-text;
                        font-family: @font-light;
                        font-size: 14px;
                        font-weight: normal;
                        letter-spacing: 0;
                        border: 0;
                        transition: color .4s ease;

                        .desktop &:hover {
                            color: #000;
                        }
                    }
                }

                h2, > li a {
                    text-transform: uppercase;
                    margin-top: 30px;
                    font-family: @font-bold;
                    color: @color-text;
                    font-size: 14px;
                    position: relative;
                }

                h2 i {
                    display: none;
                }

                &.menu-portais-brf {
                    padding-top: 40px;

                    article {
                        position: relative;
                        min-height: 270px;

                        p {
                            max-width: 194px;
                            font-size: 13px;
                            line-height: 20px;
                            color: #a1a1a1;
                            padding-top: 15px;
                        }

                        a {
                            font-size: 13px;
                            color: #002e8b;
                            position: absolute;
                            bottom: 0;
                            transition: color .4s ease;

                            .desktop &:hover {
                                color: @color-active;

                                span {
                                    border-color: @color-active;
                                }

                                use {
                                    fill: @color-active;
                                }
                            }

                            &:visited, &:focus {
                                text-decoration: none;
                            }

                            span {
                                font-family: @font-bold;
                                //font-weight: bold;
                                border-bottom: 1px solid #002e8b;
                                transition: border-color .4s ease;
                            }

                            i {
                                width: 14px;
                                height: 17px;
                                position: relative;
                                top: 5px;
                                left: 3px;
                            }

                            use {
                                fill: #002e8b;
                                transition: all .4s ease;
                            }
                        }
                    }
                }

                hr {
                    margin: 36px 0 25px;
                }

                .button {
                    margin-right: 26px;
                }
            }
        }
    }
}

@media(max-width: 1023px) {
    z-index: 9990;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;

    .stretch {
        display: none;
    }

    .nav-secondary {
        background-color: #fff;
        padding-bottom: 6px;

        &:before {
            #m .stretch();

            content: '';
            height: 6px;
            position: absolute;
            bottom: 0;
            background: linear-gradient(to right, #a63390 4%, #012e89 100%);
            z-index: 1;
        }

        .brand .logo {
            margin: 11px 0;
        }

        .left {
            margin-left: 24px;
        }

        .menu-secondary {
            float: none;
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            left: 0;
            visibility: hidden;
            opacity: 0;
            vertical-align: middle;
            background-color: #fff;
            transition: all .4s ease;

            .lang-options {
                position: absolute;
                top: 50%;
                left: 32px;
                margin: -4px 0 0 0;
                transform: translateY(-50%);
            }

            .contact-options {
                display: none;
            }

            .social-links {
                display: none;
            }

            &.is-active {
                visibility: visible;
                opacity: 1;
            }
        }

        .btn-leave {
            display: block;
            position: absolute;
            top: 50%;
            right: 81px;
            font-size: 15px;
            letter-spacing: 1px;
            color: #083085;
            font-family: @font-bold;
            padding: 10px;
            margin-top: -4px;
            transform: translateY(-50%);
        }
    }

    .nav-primary {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: calc(~"100vh - 68px");
        transition: transform .4s ease;
        transform: translateY(-100%);
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #fff;

        &.is-active {
            transform: translateY(0);
        }

        &:before, &:after {
            display: none;
        }

        .menu-primary {
            height: 100%;
            transition: transform .4s ease;
            padding: 6px 18px 0;
            transform: translateX(0%);

            &.sub-opened {
                transform: translateX(-100%);

                > li.is-active .sub {
                    visibility: visible;
                    transition-timing-function: step-start;
                }
            }

            .back-menu {
                position: relative;
                display: inline-block;
                font-size: 14px;
                color: @color-primary;
                text-transform: uppercase;
                font-family: @font-regular;
                margin: 0 0 16px 24px;
                padding-left: 24px;

                i {
                    position: absolute;
                    width: 12px;
                    height: 16px;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);

                    svg {
                        width: 100%;
                        height: 100%;
                    }

                    use {
                        fill: @color-primary;
                    }
                }
            }

            > li {
                display: block;
                border-top: 1px solid @color-border;

                &:first-child {
                    border: 0;
                }

                &.social {
                    padding: 74px 14px 30px;

                    [data-component="social-links"] {
                        a.btn {
                            margin: 0 0 0 15px;
                            width: 44px;
                            height: 44px;

                            &:first-child {
                                margin-left: 0;
                            }

                            &.btn-facebook i {
                                width: 24px;
                                height: 24px;
                            }
                            &.btn-linkedin i {
                                width: 20px;
                                height: 20px;
                            }
                            &.btn-youtube i {
                                width: 23px;
                                height: 23px;
                            }
                            &.btn-twitter i {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }

                > a {
                    display: block;
                    font-size: 15px;

                    span {
                        display: block;
                        border: 0;
                        padding: 20px 14px;
                    }
                }

                .sub {
                    height: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    padding: 20px;
                    box-shadow: none;
                    opacity: 1;
                    overflow-x: hidden;
                    overflow-y: scroll;
                    -webkit-overflow-scrolling: touch;
                    transform: translateX(100%);
                    transition-property: visibility;
                    transition-duration: .4s;
                    transition-timing-function: step-end;

                    &.menu-portais-brf {
                        padding: 20px;

                        article {
                            min-height: 0;
                            margin-bottom: 40px;

                            p {
                                max-width: 500px;
                                margin-bottom: 20px;
                            }

                            a {
                                position: relative;
                            }
                        }
                    }

                    h2 {
                        position: relative;
                        margin: 0;
                        padding: 16px 12px;
                        border-top: 1px solid @color-border;

                        i {
                            position: absolute;
                            display: block;
                            width: 9px;
                            height: 16px;
                            top: 50%;
                            right: 12px;
                            transform: translateY(-50%) rotate(90deg);

                            svg {
                                width: 100%;
                                height: 100%;
                            }

                            use {
                                fill: @color-text;
                            }
                        }
                    }

                    .col-sm-3 {
                        width: 100%;
                    }

                    ul {
                        display: none;

                        &.sub-contact-us {
                            display: block;

                            li {
                                background-color: transparent;

                                a {
                                    position: relative;
                                    display: block;
                                    font-family: @font-bold;

                                    i {
                                        position: absolute;
                                        display: block;
                                        width: 9px;
                                        height: 16px;
                                        top: 50%;
                                        right: 12px;
                                        transform: translateY(-50%);

                                        svg {
                                            width: 100%;
                                            height: 100%;
                                        }

                                        use {
                                            fill: @color-text;
                                        }
                                    }
                                }
                            }
                        }

                        li {
                            border-top: 1px solid @color-border;
                            margin: 0;
                            background-color: #f9f9f9;

                            a {
                                padding: 16px 12px;
                            }
                        }
                    }

                    hr {
                        display: none;
                    }

                    .button {
                    	position: relative;
                    	margin: 25px auto 0;
                    }
                }
            }
        }
    }

    .main-menu {
        overflow: hidden;
        background-color: #fff;
        top: 74px;
        padding: 0 15px;
        right: 0;
        max-height: 0;
        display: block;
        width: 100%;
        opacity: 0;

        a {
            display: block !important;
        }

        li, li .sub h2, li .sub ul li a, li .sub > li a {
            border-bottom: 1px solid #f2f2f2;
            line-height: 54px;
        }

        li .sub h2, li .sub ul li a,  li .sub > li a {
            text-indent: 13px;
        }

        li {
            padding-left: 0;
            display: block;

            &.social {
                line-height: 1em;
            }

            .social-links {
                padding: 15px 0;

                a {
                    display: inline-block !important;
                    border-width: 1px;
                }

                a.btn {
                    width: 36px;
                    height: 36px;
                    margin: 0 12px 0 0;

                    &.btn-youtube i {
                        width: 19px;
                        height: 19px;
                    }

                    &.btn-linkedin i {
                        width: 16px;
                        height: 16px;
                    }

                    &.btn-facebook i {
                        width: 20px;
                        height: 20px;
                    }

                    &.btn-twitter i {
                        width: 20px;
                        height: 20px;
                    }
                }

                use {
                    fill: #91499d;
                }
            }

            &.has-sub {
                a {
                    font-size: 15px;

                    &.is-active {
                        background: url(../images/icon-left-arrow.svg) no-repeat;
                        background-size: 16px 16px;
                        background-position: 10px center;
                        padding-left: 35px;
                    }
                }
            }

            ul {
                overflow: hidden;
                padding: 0;
                transition: all .4s;
                transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
            }

            a {
                font-size: 15px;
            }

            .button, .line {
                display: none !important;
            }

            .sub {
                max-height: 0;
                display: block;
                overflow: hidden;
                display: inherit;
                position: static;
                padding-top: 0;
                padding-bottom: 0;
                transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

                &.has-trhee-sub {
                    h2, > li a {
                        &:before {
                            content: '';
                            background: url(../images/icon-down-arrow.svg) no-repeat;
                            background-position: right center;
                            background-size: 16px 16px;
                            transition: transform .4s;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            display: block;
                            width: 16px;
                            height: 16px;
                        }

                        &.is-active:before {
                            transform: translateY(-50%) rotate(180deg);
                        }
                    }
                }

                &.opened {
                    max-height: 60em;
                    padding: 0;
                }

                h2, li, > li a {
                    display: block;
                    margin-top: 0;
                }

                ul {
                    background-color: #f9f9f9;
                    max-height: 0;
                    overflow: hidden;
                    display: inherit;
                    width: 100%;

                    &.opened {
                        max-height: 50em;
                    }

                    li a {
                        font-family: @font-light;
                        font-size: 15px;
                        color: #666666;
                    }
                }
            }
        }

        &.is-active {
            opacity: 1;
            max-height: 90em;
            padding-top: 6px;

            &:before {
                content: "";
                transition: all .8s;
                height: 6px;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(90deg,#a63390 4%,#012e89);
            }
        }
    }

    .header_top {
        .btn-search {
            border-left: 1px solid #ebebeb;
            top: 50%;
            transform: translateY(-50%);
            right: 100px;
            padding: 22px;
            right: 67px;

            a {
                padding: 0;
            }
        }
    }

    [data-component=hamburger] .hamburger {
        position: absolute;
        right: 0;
        top: 0;
        padding: 24px;
        border-left: 1px solid #ebebeb;
    }
}

@media(min-width: 1024px) {
    [data-component="hamburger"] .hamburger, .hide-desk {
        display: none !important;
    }

    // .menu > li > a {
    //         &:hover {
    //             border-color: #1b2b8b;
    //         }
    //     }

    //     &:hover {
    //         .sub {
    //             display: block;
    //         }
    //     }
    // }
}

        }