
        [data-component="card"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

#m .card-appearance();
transition: box-shadow .4s ease;
transform: translateZ(0);

.desktop &:hover {
    //background-color: rgba(0,0,0,.03);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06), 0px 0px 20px 0px rgba(0, 0, 0, 0.2);

    &:before {
        visibility: hidden;
        opacity: 0;
    }

    &:after {
        visibility: visible;
        opacity: 1;
    }

    img {
        transform: scale(1.1) translateZ(0);
    }
}

padding: 0 0 5px;

&:before, &:after {
    position: absolute;
    content: "";
    height: 5px;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all .4s ease;
}

&:before {
    visibility: visible;
    background: linear-gradient(90deg,#a63390 4%,#012e89);
    //background: @color-primary;
}

&:after {
    visibility: hidden;
    opacity: 0;
    background: linear-gradient(90deg,#ffc22d 4%,#ff6411);
    //background: @color-active;
}

a {
    display: block;
}

figure {
    display: block;
    width: 100%;
    overflow: hidden;

    img {
        width: 100%;
        transform: scale(1) translateZ(0);
        transition: transform .4s ease;
    }
}


.text {
    font-size: 13px;
    color: @color-text;
    font-family: @font-light;
    padding: 15px;

    .date-news {
        display: block;
        font-size: 11px;
    }

    h2 {
        font-size: 16px;
        color: @color-title;
        text-transform: uppercase;
        font-family: @font-regular;
        margin: 8px 0;
        padding: 0;
    }
}

.card-big & {
    &:before {
        height: 10px;
    }

    .desktop &:hover {
        img {
            transform: scale(1.05) translateZ(0);
        }
    }

    .text {
        padding: 35px 35px 45px;
        background: #FFF;
        z-index: 1;
        transform: translateZ(0);

        h2 {
            font-size: 25px;
            line-height: 100%;
            margin: 10px 0 12px;
        }
    }
}

.card-horizontal & {
    border-top: 1px solid #e6e6e6;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    box-shadow: none;
    padding: 0;

    &:before, &:after {
        display: none;
    }

    a {
        #m .group();
        padding: 20px;
    }

    figure {
        float: left;
        width: 168px;
    }

    figure + .text {
        width: calc(~"100% - 168px");
        padding: 0 0 0 20px;
    }

    .text {
        float: left;
        width: 100%;
        padding: 0;

        h2 {
            font-size: 13px;
            color: @color-text;
        }
    }
}

.card-simplified & {
    border-radius: 0;
    box-shadow: none;
    padding: 0;

    &:before, &:after {
        display: none;
    }

    a {
        #m .group();
    }

    figure {
        float: left;
        width: 100%;
    }

    .text {
        float: left;
        width: 100%;
        padding: 15px;

        h2 {
            font-size: 13px;
            color: @color-text;
            margin-bottom: 0;
        }
    }
}

@media(max-width: 767px) {
    .card-big & {
        .text {
            padding: 15px 15px 25px;

            h2 {
                font-size: 16px;
                line-height: inherit;
                margin-bottom: 12px;
            }
        }
    }

    .card-horizontal & {
        a {
            padding: 15px 10px;
        }

        figure {
            width: 110px;
        }

        figure + .text {
            width: calc(~"100% - 110px");
            padding: 0 0 0 15px;
        }
    }
}

        }