@charset 'utf-8';

@import 'vendor/_normalize';
@import 'vendor/_reset';
@import 'vendor/_bootstrap';
@import 'vendor/_fontawesome-all';

@import 'vendor/_slick';
@import 'vendor/_swiper';
@import 'vendor/_daterangepicker';
@import 'vendor/_jquery-confirm';

@import '_variables';
@import '_fonts';
@import '_mixins';
@import '_animation';
@import '_kit';

@import (inline, optional) '../../.tmp/jpg-sprite.css';
@import (inline, optional) '../../.tmp/png-sprite.css';

@media (-webkit-min-device-pixel-ratio: 1.25),
       (min-resolution: 120dpi) {

    @import (inline, optional) '../../.tmp/jpg-sprite@2x.css';
    @import (inline, optional) '../../.tmp/png-sprite@2x.css';
}

// RESET INPUT

.daterangepicker {
    display: none;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: geometricPrecision;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none !important;
}

input[type="text"], textarea, input[type="password"], input[type="email"], input[type="number"] {
    -webkit-appearance: none;
}

strong, b {font-family: @font-bold;}
img {max-width: 100%;}
a {color: inherit}
::placeholder {color: @color-placeholder}

i, a {
    display: inline-block;
}

// GLOBAL STYLES

body {
    font-family: @font-regular;
    font-size: 13px;
}

svg {
    width: 100%;
    height: 100%;
}

hr {
    border-top: solid 1px #e6e6e6;
    padding: 0;
}

fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

.overflow-hidden {
    overflow: hidden;
}


@media all and (-ms-high-contrast:none) {
    select::-ms-expand {
        display: none;
    }

    select.field-form {
        padding-right: 32px;
        background-image: url(../images/icon-down-arrow.svg);
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 22px 12px;
    } /* IE10 */

    *::-ms-backdrop, select.field-form {
        padding-right: 32px;
        background-image: url(../images/icon-down-arrow.svg);
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 22px 12px;
    } /* IE11 */
}

.no-margin {
    margin: 0;
}

.wrapper {
    position: relative;
    max-width: @wrapper-width;
    margin: 0 auto;
    overflow: hidden;
}

.stretch {
    #m .stretch();
}

.content {
    #m .group();

    position: relative;
}

.left {
    float: left;
}

.right {
    float: right;
}

.icon-close {
    display: inline-block;
    cursor: pointer;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    .box {
        width: 18px;
        height: 3px * 3 + 3px * 2;
        display: inline-block;
        position: relative;
    }

    .inner {
        display: block;
        top: 50%;
        margin-top: 3px / -2;
        transform: translate3d(0, (3px + 3px) * -1, 0) rotate(-45deg);

        &,
        &::before,
        &::after {
            width: 18px;
            height: 3px;
            background-color: #083085;
            border-radius: 1.5px;
            position: absolute;
        }

        &::before,
        &::after {
            content: "";
            display: block;
        }

        &::after {
            bottom: (3px + 3px) * -1;
            top: 0;
            opacity: 0;
        }

        &::before {
            top: 0;
            transform: rotate(-90deg);
        }
    }
}

.svg-icon-lib {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

.btn-close {
    position: absolute;
    display: block;

    i {
        display: block;
        width: 100%;
        height: 100%;
        transition: all .4s ease;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .desktop &:hover {
        i {
            transform: rotate(90deg);
        }
    }
}

.button {
    border: 2px solid @color-primary;
    color: @color-primary;
    border-radius: 30px;
    text-transform: uppercase;
    display: inline-block;
    padding: 12px 25px 13px;
    text-align: center;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.5px;
    font-family: @font-regular;
    position: relative;
    background-color: transparent;
    transition: color .3s ease, background-color .3s ease;

    .desktop &:hover {
        &:not(.disabled) {
            color: #fff;
            background-color: @color-primary;

            use {
                fill: #fff;
            }
        }
    }

    svg {
        display: block;
        width: 100%;
        height: 100%;
    }

    use {
        fill: @color-primary;
        transition: all .3s ease;
    }


    i {
        position: absolute;
        display: block;
        top: 50%;
        transition: all .4s ease;
        transform: translateY(-50%);
    }

    &.disabled {
        cursor: default;
        opacity: .3;
    }

    &.button-icon {
        i {
            width: 25px;
            height: 25px;
        }

        span {
            padding-left: 34px;
        }
    }

    &.button-icon-right {
        i {
            right: 25px;
        }

        span {
            padding-right: 30px;
            padding-left: 0;
        }
    }

    &.button-block {
        display: block;
        padding: 12px 10px 13px;
    }

    &.button-orange {
        border-color: @color-active;
        color: @color-active;

        .desktop &:hover {
            &:not(.disabled) {
                background-color: @color-active;
            }
        }

        use {
            fill: @color-active;
        }
    }

    &.button-gray {
        border-color: #9d9d9d;
        color: #9d9d9d;

        .desktop &:hover {
            &:not(.disabled) {
                background-color: #9d9d9d;
            }

        }

        use {
            fill: #9d9d9d;
        }
    }

    &.button-barcode {
        i {
            width: 18px;
            height: 18px;
        }
    }

    &.button-message {
        i {
            width: 24px;
            height: 22px;
        }

        use {
            fill: none;
            stroke: #ec6d2a;
            stroke-width: 2px;
            fill-rule: evenodd;
        }
    }

    &.button-download {
        border-color: @color-primary;
        color: @color-primary;

        i {
            width: 21px;
            height: 25px;
        }
    }

    &.button-arrow {
        .desktop &:hover {
            &:not(.button-icon-right) {
                &:not(.disabled) {
                    i {
                        margin-left: -5px;
                    }
                }
            }
        }

        i {
            width: 8px;
            height: 12px;
        }

        span {
            padding-left: 20px;
        }

        &.button-icon-right {
            .desktop &:hover {
                &:not(.disabled) {
                    i {
                        margin-right: -5px;
                    }
                }
            }

            span {
                padding-right: 20px;
                padding-left: 0;
            }
        }
    }
}

.breadcrumb {
    font-size: 13px;
    color: #fff;
    font-family: @font-regular;

    span {
        display: inline-block;
        padding: 0 7px;
    }

    a {
        color: #fff;

        i {
            display: inline-block;
            width: 15px;
            height: 14px;

            svg {
                width: 100%;
                height: 100%;
            }

            use {
                fill: #fff;
            }
        }
    }
}

.field-form {
    display: inline-block;
    height: 42px;
    color: @color-text;
    font-size: 13px;
    line-height: 42px;
    font-family: @font-light;
    border: 1px solid @color-border;
    border-radius: 5px;
    padding: 0 10px;
    background-color: #fff;
    transition: border-color .4s ease;

    &.disabled, &[disabled="disabled"] {
        cursor: default;
        background-color: @color-disabled;
        color: fade(@color-text, 70);
        opacity: 1 !important;
        -webkit-opacity:1 !important;
        -webkit-text-fill-color: fade(@color-text, 70) !important;
    }

    &:focus {
        border-color: @color-primary;
    }

    &.error {
        border-color: red;
    }
}

.captcha {
    margin-bottom: 15px;
}

.box-form {
    transform: translateZ(0);

    label, .selectbox {
        display: block;
        width: 100%;
        margin-bottom: 20px;

        span {
            display: block;
            font-family: @font-regular;
            margin-bottom: 10px;
        }

        .field-form {
            width: 100%;
        }
    }
}

.checkbox {
    position: relative;
    float: left;
    width: 16px;
    height: 16px;
    padding: 0;

    i {
        position: absolute;
        width: 10px;
        height: 8px;
        top: 50%;
        left: 50%;
        opacity: 0;
        transition: opacity .4s ease;
        transform: translate(-50%,-50%);

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

        use {
            fill: @color-title;
        }
    }

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 2;

        &:checked + i {
            opacity: 1;
        }
    }

    & + label {
        float: left;
        line-height: 16px;
        margin-left: 10px;
    }
}

//Calendar
.daterangepicker {
    font-size: 13px;
    padding: 10px 10px 15px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05), 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

    .header-daterangepicker{
        display: none;
    }

    .icon-arrow-right, .icon-arrow-left {
        width: 7px;
        height: 12px;
        transition: transform .4s ease;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

        use {
            fill: @color-text;
        }
    }

    .prev {
        .desktop &:hover {
            i {
                transform: translateX(-3px);
            }
        }
    }

    .next {
        .desktop &:hover {
            i {
                transform: translateX(3px);
            }
        }
    }

    .daterangepicker_input {
        .icon-calendar {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 7px;
            left: 7px;

            svg {
                display: block;
                width: 100%;
                height: 100%;
            }

            use {
                fill: @color-text;
            }
        }
    }

    .input-mini.active {
        border-color: @color-primary;
    }

    th {
        color: @color-primary;
        font-family: @font-bold;
        transition: background-color .4s ease;

        &.month {
            padding-bottom: 5px;
        }
    }

    td {
        transition: background-color .4s ease;

        &.in-range {
            background-color: lighten(@color-primary, 50%);
        }

        &.active, &.active:hover {
            background-color: @color-primary;
        }

    }

    &.show-calendar .calendar {
        display: inline-block;
    }

    &.ltr {
        .calendar, .ranges {
            float: none;
        }

        .ranges {
            float: none;
            width: 100%;
            margin: 0;
            padding: 0 4px;
            margin-top: 10px;

            .range_inputs {
                #m .group();
                border-top: 1px solid @color-border;
                padding-top: 15px;
                margin: 0;

                button {
                    float: right;
                    font-size: 13px;
                    line-height: 13px;
                    margin-left: 10px;
                    padding: 8px 20px 9px;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        position: fixed;
        max-width: 100%;
        width: 100%;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        z-index: 9999 !important;
        text-align: center !important;
        padding-top: 52px;
        border-radius: 0;
        box-shadow: none;
        overflow-y: scroll;
        overflow-x: hidden;

        .header-daterangepicker {
            #m .group();
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            display: block;
            background-color: @color-primary;
            z-index: 2;

            .btn-close {
                position: relative;
                float: right;
                width: 18px;
                height: 18px;
                box-sizing: content-box;
                padding: 12px;

                i use {
                    fill: #fff;
                }
            }
        }

        &.ltr .ranges .range_inputs {
            display: flex;
            justify-content: center;
            text-align: center;

            button {
                float: none;
                margin: 0 5px;
            }

            .applyBtn {
                order: 2;
            }

            .cancelBtn {
                order: 1;
            }
        }
    }
}

.btn-play {
    position: absolute;
    width: 62px;
    height: 62px;
    top: 50%;
    left: 50%;
    cursor: pointer;
    margin: -31px 0 0 -31px;
    border: 2px solid #fff;
    border-radius: 50%;
    transition: transform .4s ease;
    z-index: 1;

    i {
        position: absolute;
        width: 18px;
        height: 24px;
        top: 50%;
        left: 50%;
        margin: -12px 0 0 -6px;
        transition: transform .4s ease;

        svg {
            width: 100%;
            height: 100%;
        }

        use {
            fill: #fff;
        }
    }

    .desktop &:hover, .desktop .container-play:hover & {
        transform: scale(1.1);

        i {
            transform: scale(.8);
        }
    }
}

.slider-arrow {
    position: absolute;
    width: 16px;
    height: 30px;
    top: 50%;
    cursor: pointer;
    margin-top: -25px;
    z-index: 2;
    box-sizing: content-box;
    padding: 10px;

    .desktop &:hover {
        use {
            fill: darken(@color-border, 10%);
        }
    }

    i, svg {
        display: block;
        width: 100%;
        height: 100%;
    }

    use {
        fill: @color-border;
        transition: all .4s ease;
    }

    &.slider-arrow-prev {
        left: -10px;

        .desktop &:hover {
            &:not(.swiper-button-disabled) {
                i {
                    animation: arrowLeft 3s ease infinite;
                }
            }
        }
    }

    &.slider-arrow-next {
        right: -10px;

        .desktop &:hover {
            &:not(.swiper-button-disabled) {
                i {
                    animation: arrowRight 3s ease infinite;
                }
            }
        }
    }

    &.swiper-button-disabled {
        opacity: .2;
    }
}

.video-responsive {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin-bottom: 35px;

    iframe, object, embed, video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

.title {
    font-size: 25px;
    line-height: 30px;
    font-family: @font-regular;
    color: @color-primary;
    margin-bottom: 25px;
}

.has-error, .has-error input, .has-error select, .error-message {
   color: @color-error;
   border-color: @color-error;
}

.error-message {
    font-size: 12px;
    padding-top: 3px;
    margin: 0;
}



main {
    position: relative;
    padding-bottom: 70px;

    .row {
       margin: 0;
    }

    .content-press-room {
        float: left;
        width: calc(~"100% - 200px");
        font-size: 13px;
        color: @color-text;
        font-family: @font-light;
        padding-right: 40px;
    }

    .sidebar {
        float: right;
        width: 200px;

        [data-component="card-login"] {
            margin-bottom: 20px;
        }

        .latest-news {
            margin-top: 40px;

            h3 {
                font-size: 16px;
                line-height: 100%;
                color: @color-title;
                text-transform: uppercase;
            }

            .card-item {
                margin-top: 20px;
            }
        }

    }

    .card-mosaic {
        width: calc(~"100% + 20px");
        margin-left: -20px;

        .card-item {
            margin: 20px 0 0 20px;
        }
    }

    .box-pagination {
        margin-top: 60px;
    }

    .card-horizontal-list {
        margin-top: 60px;

        h2 {
            font-size: 21px;
            color: @color-title;
            text-transform: uppercase;
            font-family: @font-light;
        }

        .card-mosaic {
            width: calc(~"100% + 20px");
            margin-left: -20px;
            margin-top: 15px;
        }

        .card-item {
            width: calc(~"50% - 20px");
            margin: 0 0 0 20px;
        }
    }

    .card-simplified-list {
        margin-top: 60px;

        h2 {
            font-size: 21px;
            color: @color-title;
            text-transform: uppercase;
            font-family: @font-light;
        }

        .card-mosaic {
            width: calc(~"100% + 20px");
            margin-left: -20px;
            margin-top: 15px;
        }

        .card-item {
            width: calc(~"25% - 20px");
            margin: 0 0 0 20px;
        }
    }

    .card-related-list {
        margin-top: 60px;

        h2 {
            font-size: 21px;
            color: @color-title;
            text-transform: uppercase;
            font-family: @font-light;
        }
    }

    /*---- Home ----*/
    .news-list {
        margin-top: 60px;

        .card-item {
            width: calc(~"25% - 20px");
        }
    }

    /*---- Busca ----*/
    .text-search-result {
        font-size: 25px;
        font-family: @font-regular;
        margin-bottom: 30px;

        span {
            color: @color-primary;
        }

        .period {
            font-size: 13px;
            font-family: @font-light;
        }
    }

    .list-result {
        .card-item {
            width: calc(~"33.33% - 20px");
        }
    }

    /*---- Noticias ----*/
    .news {
        font-size: 13px;
        color: @color-text;
        font-family: @font-light;
        margin-top: 40px;

        &:last-child {
            margin-bottom: 0;
        }

        .date-news {
            display: block;
            font-size: 11px;
            margin-bottom: 5px;
        }

        .title {
            text-transform: uppercase;
        }

        p {
            line-height: 22px;
            margin-bottom: 35px;

            + p {
                margin-top: -20px;
            }

            a {
                color: @color-primary;
                text-decoration: underline;
                font-family: @font-regular;
                transition: color .4s ease;

                .desktop &:hover {
                    color: darken(@color-primary, 15%);
                }
            }
        }

        b, strong {
            font-family: @font-bold;
        }

        u {
            text-decoration: underline;
        }

        > ul {
            padding-left: 17px;
            margin: 0 35px 35px;

            li {
                list-style: disc;
                line-height: 25px;
            }
        }

        > ol {
            padding-left: 17px;
            margin: 0 35px 35px;

            li {
                list-style: decimal;
                line-height: 25px;
            }
        }

        blockquote {
            position: relative;
            display: block;
            color: inherit;
            font-size: 20px;
            line-height: 23px;
            font-family: @font-regular;
            margin-bottom: 35px;
            background: none;
            padding-left: 85px;

            &:before {
                position: absolute;
                content: "";
                width: 45px;
                height: 38px;
                top: 0;
                left: 20px;
                background: url(../images/icon-aspas.svg) no-repeat center;
                background-size: contain;
            }
        }

        div.no-quotation-marks {
            position: relative;
            display: block;
            color: inherit;
            font-size: 20px;
            line-height: 23px;
            font-family: @font-regular;
            margin-bottom: 35px;
            background: none;
        }

        .photo-news {
            display: block;
            max-width: 100%;
            max-height: 778px;
            margin: 0 auto 35px;
        }


        .box-content-featured-01 {
            position: relative;
            display: block;
            width: 100%;
            max-width: 520px;
            margin: 0 auto 35px;

            img {
                margin-bottom: 5px;
            }
        }

        .box-content-featured-02 {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            max-width: 512px;
            margin: 0 auto 35px;

            img {
                max-width: 240px;
                margin-right: 15px;
            }

            figcaption {
                max-width: calc(~"100% - 255px");
            }
        }

        .box-content-image-text {
            #m .group();
            position: relative;
            width: 100%;
            margin: 0 auto 35px;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                float: left;
                max-width: 150px;
                margin-right: 15px;
            }

            .text {
                float: left;
                max-width: calc(~"100% - 165px");

                p:last-child {
                    margin-bottom: 0;
                }
            }

            &.align-right {
                img {
                    float: right;
                    margin-right: 0;
                    margin-left: 15px;
                }

                .text {
                    float: right;
                    text-align: right;
                }
            }
        }

        .box-download {
            #m .card-appearance();
            position: relative;
            width: 100%;
            max-width: 520px;
            padding: 20px;
            margin: 0 auto 35px;

            .file-name {
                float: left;
                width: calc(~"100% - 186px");
                line-height: 18px;
                font-family: @font-regular;
            }

            .button {
                float: right;
            }
        }
    }

    /*---- Atendimento ----*/
    .attendance {
        p {
            line-height: 25px;
            margin-bottom: 35px;
        }

        a {
            color: inherit;
            font-family: @font-bold;
            text-decoration: underline;
        }

        .card-attendance {
            #m .card-appearance();
            width: 100%;
            max-width: 520px;
            line-height: 25px;
            padding: 20px;
            margin-bottom: 20px;

            h3 {
                font-size: 16px;
                color: @color-primary;
                font-family: @font-bold;
            }

            p {
                margin: 0;
            }

            small {
                font-size: 12px;
            }

            a {
                font-family: @font-light;
                color: inherit;
                text-decoration: none;
                transition: color .4s ease;

                .desktop &:hover {
                    color: #000;
                }
            }
        }
    }

    /*---- Acesso Exclusivo ----*/
    .exclusive-access {
        #m .group();

        p {
            margin-bottom: 15px;
        }

        .box-login {
            float: left;
            width: 46%;

            fieldset {
                #m .group();
            }

            .link-recover-password {
                float: left;
                font-size: 11px;
                color: inherit;
                text-decoration: underline;
            }

            .btn-enter {
                float: right;
                width: 180px;
            }
        }

        .box-register {
            float: right;
            width: 46%;
        }

        .button-yes {
            margin-left: 15px;
        }
    }

    /*---- Cadastro ----*/
    .register {
        .field-size-1 {
            width: calc(~"100% - 20px");
        }

        .field-size-2 {
            width: calc(~"50% - 20px");
        }

        .field-size-3 {
            width: calc(~"25% - 20px");
        }

        .personal-data {
            margin-bottom: 30px;

            p {
                margin-bottom: 10px;
            }

            .row {
                #m .group();
                margin: 0 -10px;
            }

            label, .selectbox, .box-checkbox {
                float: left;
                margin: 20px 10px;
            }

            .checkbox + label {
                width: auto;
                margin: 0 0 0 10px;
            }

            .box-checkbox {
                width: 100%;
            }
        }

        .preferences {
            border-top: 1px solid @color-border;
            padding-top: 30px;

            .row {
                margin-top: 5px;
            }

            .languages {
                .group-languages {
                    #m .group();
                    margin: 0 -10px;

                    li {
                        float: left;
                        margin: 10px;
                    }
                }
            }

            .box-form {
                margin-top: 10px;
            }

            .btn-register {
                float: right;
                width: 180px;
                margin-top: 20px;
            }

            small {
                float: left;
                display: block;
                width: 50%;
                font-size: 11px;
                margin-top: 20px;
            }
        }
    }

    /*---- Meus itens ----*/
    .my-itens {
        .card-mosaic {
            margin-top: 30px;

            .card-item {
                position: relative;
                width: calc(~"50% - 20px");
                margin: 0 0 0 20px;

                .btn-close {
                    box-sizing: content-box;
                    width: 12px;
                    height: 12px;
                    top: 0;
                    right: 0;
                    padding: 6px;
                    visibility: hidden;
                    opacity: 0;
                    transition: all .4s ease;
                    z-index: 2;

                    &:before {
                        position: absolute;
                        display: block;
                        content: attr(alt);
                        top: 50%;
                        left: 100%;
                        color: #fff;
                        font-size: 11px;
                        white-space: nowrap;
                        padding: 3px 5px;
                        margin: -10px 0 0 2px;
                        background-color: #df253d;
                    }

                    &:after {
                        position: absolute;
                        display: block;
                        content: "";
                        width: 0;
                        height: 0;
                        visibility: hidden;
                        opacity: 0;
                        top: 50%;
                        left: 100%;
                        margin: -4px 0 0 -2px;
                        border-style: solid;
                        border-width: 4px 4px 4px 0;
                        border-color: transparent #df253d transparent transparent;
                    }

                    &:before, &:after {
                        visibility: hidden;
                        opacity: 0;
                        transform: translateX(10px) translateZ(0);
                        transition: all .3s ease;
                    }

                    i use {
                        fill: #df253d;
                    }

                    .desktop &:hover {
                        &:before, &:after {
                            visibility: visible;
                            opacity: 1;
                            transform: translateX(0) translateZ(0);
                        }
                    }
                }

                .desktop &:hover .btn-close, .mobile & .btn-close {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    /*---- Banco de imagens ----*/
    .image-bank {
        &.image-bank-internal {
            .title {
                margin-bottom: 15px;
            }

            .albums-list {
                margin-top: 0;
            }
        }

        .albums-list {
            #m .group();
            margin: 10px -10px 0;

            .card-album {
                float: left;
                width: calc(~"50% - 20px");
                text-align: center;
                margin: 20px 10px;

                figure {
                    position: relative;
                    width: 100%;
                    height: 0;
                    padding-top: 56%;
                    overflow: hidden;
                    transform: translateZ(0);

                    .container-card-album {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        .album-link {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            &:before {
                                position: absolute;
                                content: "";
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                opacity: 0;
                                transition: all .4s ease;
                                background-color: @color-8;
                                z-index: 1;
                            }

                            img {
                                display: block;
                                max-width: 100%;
                                max-height: 100%;
                                transform: translateZ(0) scale(1);
                                transition: transform .4s ease;
                            }
                        }

                        .desktop &:hover {
                            .album-link:before {
                                opacity: .4;
                            }

                            img {
                                transform: scale(1.1) translateZ(0);
                            }
                        }
                    }
                }

                h3, figcaption {
                    font-size: 13px;
                    margin-top: 15px;

                    b {
                        font-family: @font-regular;
                    }
                }

                &.card-album-photo {
                    figure {
                        .desktop &:hover {
                            .icon-magnifier {
                                opacity: 1;
                                transform: scale(1);
                            }

                            .btn-download-image {
                                transform: translateY(0%);
                            }
                        }

                        .mobile & {
                            .icon-magnifier {
                                display: none;
                            }

                            .btn-download-image {
                                transform: translateY(0%);
                            }
                        }
                    }

                    .icon-magnifier {
                        position: absolute;
                        width: 50px;
                        height: 53px;
                        top: 50%;
                        left: 50%;
                        margin: -26px 0 0 -25px;
                        opacity: 0;
                        transform: scale(.7);
                        transition: all .4s ease;
                        z-index: 1;

                        svg {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }

                        use {
                            fill: #fff;
                        }
                    }

                    .btn-download-image {
                        position: absolute;
                        width: 34px;
                        height: 31px;
                        right: 15px;
                        bottom: 15px;
                        transform: translateY(150%);
                        transition: all .4s ease;
                        z-index: 1;

                        i,svg {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }

                        i {
                            transition: all .4s ease;
                        }

                        use {
                            fill: #fff;
                        }

                        .desktop &:hover {
                            i {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
    }
}


//page components
.page-components {
    hr {
        border: 1px solid #eee;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media(min-width: 1024px) {
    .hide-desk {
        display: none !important;
    }
}

@media(max-width: 1023px) {
    .container {
        width: auto;
    }

    main {
        padding-top: 68px;

        .content-press-room {
            width: 100%;
            padding-right: 0;
        }

        .sidebar {
            display: none;
        }

        .news-list {
            margin-top: 40px;

            .card-item {
                width: calc(~"50% - 20px");
            }
        }

        .card-horizontal-list {
            .card-mosaic {
                width: 100%;
                margin-left: 0;
            }

            .card-item {
                width: 100%;
                margin: 0;
            }
        }
    }
}

@media(max-width: 767px) {
    .breadcrumb {
        font-size: 11px;
    }

    .button.button-arrow {
        i {
            position: relative;
            top: 0;
            left: initial;
            right: initial;
            transform: translateY(0);
        }

        span {
            display: none;
        }
    }

    .btn-play {
        width: 44px;
        height: 44px;
        margin: -22px 0 0 -22px;

        i {
            width: 14px;
            height: 18px;
            margin: -9px 0 0 -5px;
        }
    }

    .title {
        font-size: 24px;
        line-height: 30px;
    }

    main {
        .card-horizontal-list {
            .card-item {
                width: 100%;
                margin: 0;
            }
        }

        .box-pagination {
            margin-top: 40px;
        }

        .news {
            blockquote {
                font-size: 16px;

                &.quotation-marks {
                    padding-left: 45px;

                    &:before {
                        width: 28px;
                        height: 24px;
                        left: 0;
                    }
                }
            }
        }

        .text-search-result {
            font-size: 20px;
        }

        .register {
            .field-size-2 {
                width: calc(~"100% - 10px");
            }

            .field-size-3 {
                width: calc(~"50% - 10px");
            }

            .personal-data {
                label, .selectbox {
                    margin: 10px 5px;
                }
            }
            .preferences small {
                width: 100%;
            }
        }

        .exclusive-access {
            .box-login, .box-register {
                width: 100%;
            }

            .box-register {
                margin-top: 40px;

                p {
                    width: 100%;
                }

                .button {
                    float: right;
                }
            }
        }

        .my-itens .card-mosaic .card-item {
            width: 100%;
            margin: 0;
        }

        .image-bank .albums-list {
            margin: 10px 0 0;

            .card-album {
                width: 100%;
                margin: 15px 0;


                figure {
                    padding-top: 0;
                    height: auto;

                    .container-card-album {
                        position: relative;
                        top: initial;
                        left: initial;
                        transform: translate(0,0);
                        margin: 0 auto;

                        .album-link {
                            position: relative;
                            top: initial;
                            left: initial;

                            img {
                                max-height: 300px;
                            }
                        }

                    }

                }
            }
        }
    }
}

@media(max-width: 479px) {
    main {
        .card-mosaic {
            width: 100%;
            margin-left: 0;
        }

        .news-list, .list-result {
            margin-top: 0;

            .card-item {
                width: 100%;
                margin: 20px 0 0 0;
            }
        }

        .news {
            .box-content-featured-02 {
                flex-direction: column;
                text-align: center;

                img {
                    margin: 0 0 5px;
                }

                figcaption {
                    max-width: 100%;
                }
            }

            .box-download {
                text-align: center;

                .file-name {
                    display: block;
                    float: none;
                    width: 100%;
                    margin-bottom: 15px;
                }

                .button {
                    float: none;
                }
            }
        }
    }
}
