
        [data-component="footer"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

position: relative;
background-color: #fff;
z-index: 1;

.top-footer {
    #m .stretchBG(#f5f5f5);

    padding: 63px 0 45px;

    &:before {
        border-top: 1px solid @color-border;
        border-bottom: 1px solid @color-border;
    }

    nav {
        position: relative;
        display: flex;
        justify-content: space-between;
        z-index: 2;

        .report {
            max-width: 226px;
        }
    }
}

.logo img {
    height: 100%;
}

@media(min-width: 1024px) {
    [data-component="social-links"] {
        a.btn {
            width: 28px;
            height: 28px;
            margin: 0 12px 0 0;
        }
    }
}

.button {
    margin-top: 20px;
}

a, p {
    color: #9d9d9d;
}

p {
    font-family: @font-regular;
    font-size: 14px;
}

h2 {
    text-transform: uppercase;
    font-family: @font-bold;
    font-size: 14px;
    color: #585858;
    line-height: 1em;
    padding-bottom: 16px;
}

ul {
    li {
        font-family: @font-regular;
        font-size: 14px;
        margin-top: 13px;

        &:first-child {
            margin-top: 0;
        }

        a {
            transition: color .4s ease;

            .desktop &:hover {
                color: @color-title;
            }
        }
    }
}

.logo {
    margin-left: 20px;
    height: 40px;
}

.bottom-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 72px;
    line-height: 100%;
    padding: 30px 0;

    a {
        color: @color-title;
        font-family: @font-bold;
        transition: color .4s ease;

        .desktop &:hover {
            color: #000;
        }
    }

    .left {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .tel {
        float: left;
        margin-right: 77px;

        i {
            width: 20px;
            height: 20px;
            position: relative;
            top: 3px;
        }

        use {
            fill: #7f2c86;
        }

        span {
            font-size: 22px;
            padding-left: 11px;
            color: #4d4d4d;
            font-family: @font-bold;
        }
    }

    .site-map {
        position: relative;
        float: left;
        font-size: 11px;
        padding-right: 20px;

        .desktop &:hover {
            i {
                transform: translateY(-50%) rotate(90deg);
            }
        }

        i {
            position: absolute;
            display: block;
            width: 10px;
            height: 10px;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            transition: transform .4s ease;

            svg {
                display: block;
                width: 100%;
                height: 100%;
            }

            use {
                fill: #000;
            }
        }
    }

    ul {
        position: relative;

        li {
            float: left;
            margin-top: 0;

            > span {
                padding: 0 5px;
            }
        }

        a {
            text-transform: uppercase;
            font-size: 11px;
            padding: 0 5px;
            color: @color-title;
            font-family: @font-bold;
        }
    }

    .right {
        float: right;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .logo {
        height: 38px;
    }
}

@media(max-width: 1023px) {
    .menu-footer {
        display: none;
    }

    .top-footer {
        text-align: center;
        padding-top: 33px;
        padding-bottom: 24px;

        .container {
            padding: 0;
        }

        h2 {
            font-size: 19px;
            padding-bottom: 8px;
        }

        p {
            font-size: 14px;
            font-family: @font-light;
            width: 280px;
            margin: 0 auto;
        }

        .button {
            margin-top: 15px;
        }

        nav {
            flex-direction: column;
            justify-content: center;

            .report {
                max-width: 100%;
            }
        }

        .social-footer {
            padding: 0;
            padding-top: 23px;
            margin: 36px -15px 0;
            border-top: 1px solid #e0e0e0;

            h2 {
                display: none;
            }

            [data-component="social-links"] {
                a.btn {
                    margin: 0 0 0 15px;
                    width: 44px;
                    height: 44px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &.btn-facebook i {
                        width: 24px;
                        height: 24px;
                    }
                    &.btn-linkedin i {
                        width: 20px;
                        height: 20px;
                    }
                    &.btn-youtube i {
                        width: 23px;
                        height: 23px;
                    }
                    &.btn-twitter i {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }

    .bottom-footer {
        .tel {
            margin-right: 60px;
        }
    }
}

@media(max-width: 767px) {
    .logo {
        margin: 0;
    }

    .bottom-footer {
        flex-direction: column;
        padding: 0;
        margin: 0 -15px;

        .tel {
            width: 100%;
            text-align: center;
            margin: 0;
            padding: 24px 0;
        }

        .left {
            flex-direction: column;
            width: 100%;
        }

        .right {
            flex-direction: column;
            width: 100%;

            ul {
                width: 100%;
                text-align: center;
                padding: 24px 0;
                border-top: 1px solid @color-border;
                border-bottom: 1px solid @color-border;

                li {
                    float: none;
                    display: inline-block;
                }
            }
        }

        .site-map {
            font-size: 18px;
            padding-right: 24px;

            i {
                width: 14px;
                height: 14px;
            }
        }

        .logo {
            height: 45px;
            margin: 24px 0;
        }
    }
}

@media(max-width: 360px) {
    .bottom-footer .right ul li a {
        font-size: 12px;
    }
}

        }