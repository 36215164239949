
        [data-component="social-links"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

#m .group();

display: inline-block;

.btn {
    border-radius: 50%;
    display: block;
    position: relative;
    float: left;
    width: 28px;
    height: 28px;
    border: 2px solid @color-generic-primary;
    margin-left: 10px;
    transition: transform .4s ease;

    .desktop &:hover {
        transform: scale(1.08);
    }

    &:first-child {
        margin-left: 0;
    }

    i {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) translateZ(0);

        svg {
            display: block;

            use {
                fill: @color-generic-primary;
            }
        }
    }

    &.btn-facebook i {
        width: 14px;
        height: 17px;
    }
    &.btn-linkedin i {
        width: 12px;
        height: 15px;
    }
    &.btn-youtube i {
        width: 14px;
        height: 15px;
    }
    &.btn-twitter i {
        width: 15px;
        height: 15px;
    }
}

        }