
        [data-component="slider"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

margin-top: 30px;

.swiper-pagination {
    position: relative;
    padding-top: 20px;
    text-align: center;

    .swiper-pagination-bullet {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: @color-border;
        margin: 0 6px;
        opacity: 1;
        transition: all .4s ease;

        .desktop &:hover {
            &:not(.swiper-pagination-bullet-active) {
                background-color: darken(@color-border, 7%);
            }
        }

        &.swiper-pagination-bullet-active {
            background-color: @color-title;
        }
    }
}

@media(min-width: 480px) {
    .slider-featured {
        #m .card-appearance();

        padding: 0;
        border-radius: 5px;
        overflow-x: hidden;
        transition: box-shadow .4s ease;

        .desktop &:hover {
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06), 0px 0px 20px 0px rgba(0, 0, 0, 0.2);

            &:before {
                visibility: hidden;
                opacity: 0;
            }

            &:after {
                visibility: visible;
                opacity: 1;
            }
        }

        &:before, &:after {
            position: absolute;
            content: "";
            height: 10px;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            transition: all .4s ease;
        }

        &:before {
            visibility: visible;
            background: linear-gradient(90deg,#a63390 4%,#012e89);
            //background: @color-primary;
        }

        &:after {
            visibility: hidden;
            opacity: 0;
            background: linear-gradient(90deg,#ffc22d 4%,#ff6411);
            //background: @color-active;
        }
    }

    [data-component="card"] {
        border-radius: 0;
        box-shadow: none !important;

        &:before, &:after {
            display: none;
        }

        .card-big & {
            .text {
                // position: absolute;
                // bottom: 0;
                // left: 0;
                // right: 0;
            }
        }
    }
}

@media(max-width: 479px) {
    [data-component="card"] {
        .card-big & {
            .text {
                position: relative;
                bottom: initial;
                left: initial;
                right: initial;
            }
        }
    }

    .swiper-wrapper {
        display: block;

        .slider-item {
            margin: 20px 0 0;
        }
    }

    .swiper-pagination {
        display: none;
    }
}

        }