
        [data-component="share"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

#m .group();

position: absolute;
top: 347px;
left: 50%;
margin-left: -650px;
padding: 14px;
border: 1px solid @color-border;
text-align: center;
background-color: #fff;
z-index: 1;

&.fixed {
    position: fixed;
    top: 30px;
}

.btn {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 14px;

    &:before {
        position: absolute;
        display: block;
        content: attr(alt);
        top: 50%;
        left: 100%;
        color: #fff;
        font-size: 11px;
        white-space: nowrap;
        padding: 3px 5px;
        margin: -10px 0 0 8px;
        background-color: @color-primary;
    }

    &:after {
        position: absolute;
        display: block;
        content: "";
        width: 0;
        height: 0;
        visibility: hidden;
        opacity: 0;
        top: 50%;
        left: 100%;
        margin: -4px 0 0 4px;
        border-style: solid;
        border-width: 4px 4px 4px 0;
        border-color: transparent @color-primary transparent transparent;
    }

    &:before, &:after {
        visibility: hidden;
        opacity: 0;
        transform: translateX(10px) translateZ(0);
        transition: all .3s ease;
    }

    .desktop &:hover {
        &:not(.disabled) {
            &:before, &:after {
                visibility: visible;
                opacity: 1;
                transform: translateX(0) translateZ(0);
            }

            i {
                transform: scale(1.08) translateZ(0);
            }
        }
    }

    &:first-child {
        margin-top: 0;
    }

    i {
        display: block;
        width: 100%;
        height: 100%;
        transform: scale(1) translateZ(0);
        transition: transform .4s ease;

        svg {
            display: block;
            width: 100%;
            height: 100%;

            use {
                fill: @color-13;
            }
        }
    }

    &.btn-facebook {
        i svg use {
            fill: #386eaa;
        }
    }

    &.btn-linkedin i svg use {
        fill: #386eaa;
    }

    &.btn-twitter i svg use {
        fill: #00afde;
    }

    &.btn-whatsapp i svg use {
        fill: #2cb742;
    }

    &.disabled {
        cursor: default;
        opacity: .3;
    }

    &.active i svg use {
        fill: @color-active;
    }
}

@media screen and (max-width: 1400px) {
    margin-left: -630px;
}

@media screen and (max-width: 1326px) {
    margin-left: -600px;
}

@media screen and (max-width: 1250px) {
    position: relative;
    width: 320px;
    left: initial;
    top: initial;
    padding: 0 0 10px;
    border: 0;
    border-bottom: 1px solid @color-border;
    margin: 0 auto 25px;

    &.fixed {
        position: relative;
        top: initial;
    }

    .btn {
        display: inline-block;
        margin: 0 10px;

        &:after {
            top: 100%;
            left: 50%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 4px 4px 4px;
            border-color: transparent transparent @color-primary transparent;
            margin: 4px 0 0 0;
            transform: translate(-50%,10px);
        }

        &:before {
            top: 100%;
            left: 50%;
            margin: 8px 0 0;
            transform: translate(-50%,10px) translateZ(0);
        }

        .desktop &:hover {
            &:not(.disabled) {
                &:after {
                    visibility: visible;
                    opacity: 1;
                    transform: translate(-50%,0);
                }

                &:before {
                    transform: translate(-50%,0) translateZ(0);
                }
            }
        }
    }
}

@media screen and (max-width: 350px) {
    width: 272px;

    .btn {
        margin: 0px 7px;
    }
}

        }