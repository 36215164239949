
        [data-component="pagination"] {
            /**
 * You are inside the CSS selector of your component
 *
 * Try to add CSS properties direct below, eg:
 * background-color: red;
 */

.content-pagination {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: @color-text;

    .select-page {
        margin-left: 20px;
    }

    .page-quantity {
        font-size: 14px;
        font-family: @font-regular;
        margin: 0 20px 0 10px;
    }
}

        }